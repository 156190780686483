import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/logo192.png" className="App-logo" alt="logo" height="64px"/>
        <p>
          <code>coming soon.</code>
        </p>
      </header>
    </div>
  );
}

export default App;
